<template>
  <section
    slot="pdf-content"
    class="pdf-content"
  >
    <b-container fluid>
      <b-card no-body>
        <b-row class="mx-3">
          <b-col cols="12">
            <section class="pdf-item">
              <b-row class="mt-2">
                <b-col cols="12">
                  <b-table-simple
                    id="semesterExamTable"
                    class="table-bordered"
                    hover
                    small
                    responsive
                  >
                    <b-thead>
                      <b-tr class="text-center">
                        <b-th>Thứ tự</b-th>
                        <b-th>Mã sinh viên</b-th>
                        <b-th>Họ tên sinh viên</b-th>
                        <b-th>Căn cước công dân</b-th>
                        <b-th>Mã lớp</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <template>
                        <b-tr
                          v-for="dataSource in dataSources"
                          :key="dataSource.code"
                        >
                          <b-td>
                            {{ dataSource.rowNum }}
                          </b-td>
                          <b-td>
                            {{ dataSource.name }}
                          </b-td>
                          <b-td>
                            {{ dataSource.code }}
                          </b-td>
                          <b-td>
                            {{ dataSource.cccd }}
                          </b-td>
                          <b-td>
                            {{ dataSource.subjectCode }}
                          </b-td>

                        </b-tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </section>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </section>
</template>

<script>
import {
  BCard, BCol, BContainer, BRow, BTableSimple, BTbody, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'PdfListStudents',
  components: {
    BCard,
    BRow,
    BCol,
    BContainer,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
  },
  props: {
    dataSources: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
